


































































import { Vue, Component } from 'vue-property-decorator'
import HeaderBox from '@/components/HeaderBox.vue'
import ImagePreview from '@/components/ImagePreview.vue'

interface Transfer {
  car_model: string
  vin: string
  transfer_status: string
  store_name: string
  buyer_name: string
  buyer_mobile: string
  ensurance_status: string
  unfreeze_time: string
  operator: string
  remark: string
  refer_urls: string[]
}
@Component({
  components: {
    HeaderBox,
    ImagePreview
  },
  filters: {
    formatFreezeStatus (val) {
      return ['待解冻', '已解冻'][+val - 1];
    },
    formatStatus (val) {
      return +val === 1 ? '待过户' : '已过户';
    }
  }
})
export default class TransferInfo extends Vue {
  transferInfo: any = {}
  currentImages = []
  currentIndex = 0
  showPreviewWindow = false
  created () {
    this.getTransferInfo();
  }

  async getTransferInfo () {
    const { center_car_id } = this.$route.query
    const { code, data } = await this.$rest.carManage.getTransferInfo(center_car_id, {
      center_car_id
    });
    if (code !== 0) return;
    this.transferInfo = data || {};
  }

  formatTransferBigImages (urls) {
    let result = []
    urls.forEach(item => {
      result.push({
        // ...item,
        src: this.joinTokenToUrl(item.src.image),
        name: item.title || ''
      })
    });
    return result;
  }

  handleLookBigImages (urls, index) {
    this.currentIndex = index;
    this.currentImages = this.formatTransferBigImages(urls);
    this.showPreviewWindow = true;
  }
}
